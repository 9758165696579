<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
          lg="6"
          sm="6"
      >
        <b-card>
          <b-card-text>
            <b-img fluid src="@/assets/images/banners/interfazfenix-05.jpg" alt="Bienvenido" />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
          lg="3"
          sm="3"
      >
        <statistic-card-with-area-chart
            v-if="despachosseries.total"
            icon="UsersIcon"
            :statistic="kFormatter(despachosseries.total)"
            :statistic-title="despachosseries.name"
            :chart-data="despachosseries.series"
        />
      </b-col>
      <b-col
          lg="3"
          sm="3"
      >
        <statistic-card-with-area-chart
            v-if="transitoseries.total"
            icon="PackageIcon"
            color="warning"
            :statistic="kFormatter(transitoseries.total)"
            :statistic-title="transitoseries.name"
            :chart-data="transitoseries.series"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
          lg="4"
          sm="4"
          xs="12"
      >
        <b-card>
          <b-card-text>
            <a href="https://mifenixcargo.com/blog/" target="_blank">
              <b-img fluid src="@/assets/images/banners/interfazfenix-06.jpg" alt="Bienvenido" />
            </a>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
          lg="4"
          sm="4"
          xs="12"
      >
        <b-card>
          <b-card-text>
            <b-img fluid src="@/assets/images/banners/interfazfenix-07.jpg" alt="Bienvenido" />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
          lg="4"
          sm="4"
          xs="12"
      >
        <b-card>
          <b-card-text>
            <a href="https://mifenixcargo.com/las-mejores-tiendas-americanas-para-comprar-desde-colombia/" target="_blank">
              <b-img fluid src="@/assets/images/banners/interfazfenix-08.jpg" alt="Bienvenido" />
            </a>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { BImg, BCard, BCardText, BLink, BCol, BRow } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import store from "@/store";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BCol,
    BRow,
    StatisticCardWithAreaChart,
  },
  data(){
    return {
      despachosseries: {
        total: '',
        name: "Despachos",
        series: [
              {
                "data": [28,40,36,22,17,51]
              }
        ]
      },
      transitoseries: {
        total: '',
        name: "Historial Transito",
        series: [
          {
            "data": [5,20,11,32,41,56]
          }
        ]
      }
    }
  },
  methods:{
    loadDespachos() {
      const str = `Bearer ${this.$session.get('userData').access_token}`;
      this.$http
          .post(`api/casillero/despachos/preliquidaciones/paqsdisponibles?page=${this.currentPage}`,
              {
                str_busqueda: '',
                pageSize:1
              },
              {
                headers: { Authorization: str }
              }
          )
          .then((response) => {
            this.despachosseries.total = response.data.datos.total
          })
          .catch((errors) => {
            console.log(errors);
          });
    },
    searchHistorial() {
      let str = `Bearer ${this.$session.get('userData').access_token}`;
      this.$http
          .post(`api/casillero/clientes/historial/busqueda?page=${this.currentPage}`,
              {
                pageSize: 1,
                tipo_proceso: "TRANSITO",
                str_busqueda: "",
                fecha_inicial: "",
                fecha_final: ""
              },
              { headers: { Authorization: str } }
          )
          .then((response) => {
            this.transitoseries.total = response.data.datos.total
          })
          .catch((errors) => {
            console.log(errors);
          });
    },
    kFormatter,
  },
  beforeMount() {
    this.loadDespachos()
    this.searchHistorial()
  }
}
</script>
